<template>
  <div class="my-account-password-modify-box">
    <ul>
        <li>
            <p class="my-account-password-modify-title">手机号</p>
            <input type="number" class="my-account-password-modify-input" placeholder="请输入手机号"/>
        </li>
        <li>
            <p class="my-account-password-modify-title">验证码</p>
            <input type="number" class="my-account-password-modify-input" placeholder="请输入验证码"/>
        </li>
        <li>
            <p class="my-account-password-modify-title">新密码</p>
            <input type="text" class="my-account-password-modify-input" placeholder="请输入新密码"/>
        </li>
        <li>
            <p class="my-account-password-modify-title">确认密码</p>
            <input type="text" class="my-account-password-modify-input" placeholder="请再次输入密码"/>
        </li>
        <div class="my-account-password-modify-btn">
            <p class="my-account-password-modify-btn-text">保存</p>
        </div>
        <div class="my-account-password-modify-verification-code">
            <p class="my-account-password-modify-verification-code-text">获取验证码</p>
        </div>
    </ul>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.my-account-password-modify-box{
    margin-top: 20px;
    width: 1200px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    ul{
        width: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        padding: 0px;
        position: relative;
        li{
            width: 420px;
            height: 70px;
            list-style: none;
            margin-bottom: 30px;
            .my-account-password-modify-title{
                height: 14px;
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #555555;
                line-height: 14px;
                text-align: left;
                font-style: normal;
            }
            .my-account-password-modify-input{
                margin-top: 10px;
                width: 420px;
                height: 46px;
                background: #FFFFFF;
                border-radius: 8px;
                border: 1px solid #F0F0F0;
                padding-left: 14px;
                outline: none;
                &::placeholder{
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #CCCCCC;
                    line-height: 14px;
                    text-align: left;
                    font-style: normal;
                }
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }
            &:nth-child(2) .my-account-password-modify-input{
                width: 290px;
            }
        }
        .my-account-password-modify-btn{
            width: 200px;
            height: 46px;
            background: #F02233;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .my-account-password-modify-btn-text{
                height: 14px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 14px;
                text-align: right;
                font-style: normal;
            }
        }
        .my-account-password-modify-verification-code{
            position: absolute;
            left: 365px;
            top: 124px;
            width: 120px;
            height: 46px;
            background: #F6F7FF;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .my-account-password-modify-verification-code-text{
                height: 14px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 14px;
                color: #F02233;
                line-height: 14px;
                text-align: right;
                font-style: normal;
            }
        }
    }

}
</style>